import React, { useMemo } from "react";

import { useReviewContext } from "~/views/reviews/_lib/context";
import { spinnerStyles } from "~/views/reviews/_lib/styles";
import { Status, useReviewLeads } from "@api/reviews";

import Box from "@material-ui/core/Box";

import { PendingList } from "./PendingList";
import { Spinner } from "~/components/Spinner";

export const ReviewPendingDisplay: React.FC = () => {
    const { companyId } = useReviewContext();
    const { data, error, loading } = useReviewLeads({ companyId, status: Status.PENDING });
    const content = useMemo(() => {
        if (error) {
            return (
                <span>{error}</span>
            );
        }

        if (loading) {
            return <Spinner style={spinnerStyles} />;
        }

        return (
            <PendingList data={data} />
        );
    }, [error, loading, data]);

    return (
        <Box
            display="flex"
            justifyContent={loading || error ? "center" : ""}
            flexDirection="column"
            flexWrap="nowrap"
            height="100%"
        >
            {content}
        </Box>
    );
};